import { observable, action } from 'mobx'
import type { Message, MessageResponse, UserMessage } from '../utils/models'
import { StrategyType, MessageOp, StatusType, GroupType } from '../utils/types'
import { OperationType } from '../utils/operation'
import { newGuid } from '../utils/generates'
import groupsStore from './groups.store'
import messagesStore from './messages.store'
import api from '../service/api'
import userStore from './user.store'
import { addDays, isAfter, isBefore } from 'date-fns'


const IsJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const extractMessagePayload = (message: Message) => {
    let response = {
        text: '',
        fileUrl: undefined,
        mediaType: undefined,
        survey: undefined,
        videoThumbnailUrl: undefined,
        videoThumbnailType: undefined,
    }
    const strategy = message.msg_strategy
    if (strategy === StrategyType.media) {
        if (IsJsonString(message.payload)) {
            const data = JSON.parse(message.payload)
            if(data?.files?.length && 
                data?.files?.length == 2 && 
                data.files[0].fileUrl != null && 
                data.files[1].fileUrl != null &&
                data.files[1].type == "VIDEO"
              ) {
                response.text = data.text
                response.fileUrl = data.files[1].fileUrl
                response.mediaType = data.files[1].type
                response.videoThumbnailUrl = data.files[0].fileUrl
                response.videoThumbnailType = data.files[0].type
            } else if (data?.files?.length && data.files[0].fileUrl != null) {
                response.text = data.text
                response.fileUrl = data.files[0].fileUrl
                response.mediaType = data.files[0].type
            }
        } else {
            response.text = message.payload

        }

    } else if (/^QS/.test(strategy) && IsJsonString(message.payload)) {
        try {
            const data = JSON.parse(message.payload)
            data?.answers?.forEach(a => a.id = newGuid())
            response.survey = data.answers
            response.text = data.question

        } catch (error) {
            response.survey = []
            response.text = message.payload
        }

    } else {
        response.text = message.payload
    }

    return response
}

class NewMessageStore {

    constructor() {

    }

    @observable currentUserMessage: UserMessage | undefined = undefined;
    @observable surveyAnswers: Array<any> | undefined = undefined;
    @observable videoThumbhail: { url: any, type?: 'IMAGE', local: boolean } | undefined = undefined;
    @observable files: { url: any, type?: 'PDF' | 'IMAGE' | 'VIDEO', local: boolean } | undefined = undefined;
    @observable messageText: string = '';
    @observable locationRequest: boolean = false;
    @observable schedule: Date | undefined = undefined
    @observable expiry: Date | undefined = undefined

    @action createNewMesage = () => {
        const fullName = `${userStore.user.given_name} ${userStore.user.nick_name ? '(' + userStore.user.nick_name + ')' : ''} ${userStore.user.family_name}`
        const message: Message = {
            create_guid: newGuid(),
            created: new Date(),
            updated: new Date(),
            expiry: addDays(new Date(), 7),
            schedule: undefined,
            // group: groupsStore.currentGroupId,
            group: groupsStore.currentGroup?.group.id ? groupsStore.currentGroup?.group.id : groupsStore.currentGroup.group.parentGroupId,
            id: 0,
            payload: '',
            msg_strategy: undefined,
            senderName: fullName,
            senderId: userStore.user?.user_id,
            author_name: fullName
            // received?: Date | undefined
        }


        const defaultNewMessage: UserMessage = {
            user: userStore.user?.user_id || 0,
            message: message,
            status: 0,
            response: 0
        }

        this.currentUserMessage = defaultNewMessage
        this.surveyAnswers = undefined
        this.files = undefined
        this.messageText = ''
        this.locationRequest = false
        this.schedule = undefined
        this.expiry = addDays(new Date(), 7)

    };
    @action createNewSurveyMessage = () => {
        this.createNewMesage()
        this.setSurveyAnswers([{ id: newGuid(), text: '', color: '' }])
    };

    @action updateMessageText = (text: string) => {
        this.messageText = text
    };

    @action updateLocationRequestToggle = (enabled: boolean) => {
        this.locationRequest = enabled
    }

    @action updateExpiryDate = (date: Date) => {
        this.expiry = date
    };
    @action updateScheduleDate = (date: Date | undefined) => {
        this.schedule = date
    };
    @action setSurveyAnswers = (answers: Array<any>) => {
        this.surveyAnswers = [...answers]
    };
    @action clearSurveyAnswers = () => {
        this.surveyAnswers = undefined
    };

    @action setCurrentUserMessage = (userMessageId: number) => {
        const userMessage = messagesStore.getById(userMessageId)
        if (userMessage) {
            this.currentUserMessage = userMessage
            const response = extractMessagePayload(userMessage.message)
            const { text, fileUrl, mediaType, survey } = response
            this.updateMessageText(text)

            if (fileUrl && mediaType) {
                this.setFiles(fileUrl, mediaType, false)
                this.setVideoThumbnail(response.videoThumbnailUrl, response.videoThumbnailType, false)
            }

            if (survey) {
                this.setSurveyAnswers(survey)
            }
            this.expiry = userMessage?.message?.expiry
            this.schedule = userMessage?.message?.schedule
        }
    };

    @action setFiles = (file: any, type: 'PDF' | 'IMAGE' | 'VIDEO', local: boolean) => {
        this.files = { url: file, type: type, local: local }
    };

    @action setVideoThumbnail = (file: any, type: 'IMAGE', local: boolean) => {
        this.videoThumbhail = { url: file, type: type, local: local }
    };

    @action clearFiles = () => {
        this.files = undefined
        this.videoThumbhail = undefined
    };

    @action createMessage = () => {
        const updatedMessage = this.currentUserMessage && this.currentUserMessage.message.id != 0
        if (updatedMessage) {
            this.updateMessageInFeed()
        } else {
            this.addNewMessageToFeed()
        }
    };

    @action addNewMessageToFeed = async () => {
        if (!groupsStore.currentGroup) {
            return
        }
        const userMessage = this.currentUserMessage
        if (!userMessage) return

        let messagePayload: string = this.messageText
        userMessage.message.msg_strategy = StrategyType.text

        if (this.files) {
            userMessage.message.msg_strategy = StrategyType.media
            if (this.files.type === 'IMAGE') {
                messagePayload = JSON.stringify({ text: this.messageText, files: [{ fileUrl: URL.createObjectURL(this.files.url), type: this.files.type, }] })    
            } else if (this.files.type === 'VIDEO') {
                messagePayload = JSON.stringify({ 
                    text: this.messageText, 
                    files: [
                        { fileUrl: URL.createObjectURL(this.videoThumbhail?.url), type: this.videoThumbhail?.type, }, 
                        { fileUrl: URL.createObjectURL(this.files.url), type: this.files.type, }
                    ] 
                })
            } else {
                messagePayload = JSON.stringify({ text: this.messageText, files: [{ fileUrl: { url: URL.createObjectURL(this.files.url) }, type: this.files.type }] })
            }    
        }

        if (this.surveyAnswers) {
            messagePayload = JSON.stringify({ question: this.messageText, answers: this.surveyAnswers })
            userMessage.message.msg_strategy = StrategyType.survey + this.surveyAnswers.length
        }

        userMessage.message.payload = messagePayload
        userMessage.message.expiry = this.expiry
        userMessage.message.locationRequest = this.locationRequest

        if (this.schedule && isBefore(new Date(), this.schedule)) {
            userMessage.message.schedule = this.schedule
        }
        userMessage.status = StatusType.OUTGOING

        messagesStore.addMessage(userMessage)

        const message = userMessage.message
        const op = groupsStore.currentGroup.group.type === GroupType.SUPPORT ? OperationType.CREATE_SUPPORT_MESSAGE : OperationType.CREATE_MESSAGE
        let requestOp: MessageOp = {
            op: op,
            group: message.group < 0 ? groupsStore.currentGroup?.group.parent_id! : message.group,
            sender: message?.senderId,
            type: message.msg_strategy,
            payload: this.surveyAnswers ? userMessage.message.payload : this.messageText,
            expiry: message.expiry?.toISOString(),
            guid: message.create_guid,
            schedule: message.schedule?.toISOString() || undefined,
            locationRequest: message.locationRequest
        }
        if (message.id > 0) {
            requestOp.message = message.id
        }
        if (op === OperationType.CREATE_SUPPORT_MESSAGE && message.group < 0) {
            requestOp.CreateNewIssue = true
        }

        let blob = undefined
        if (this.files) {
            if (this.files?.type === 'IMAGE') {
                blob = this.files.url
                requestOp.files = [{ type: "IMAGE" }]
            }
            else if (this.files?.type === 'PDF') {
                blob = this.files.url
                requestOp.files = [{ type: "PDF" }]
            } 
            else if (this.files?.type === 'VIDEO') {
                blob = [this.videoThumbhail?.url, this.files.url]
                requestOp.files = [{ type: "IMAGE" }, { type: 'VIDEO' }]
            }
        }

        const res = await api.sendMessage([requestOp], blob, groupsStore.currentGroup.group.type)
        if (message.group < 0) {
            const currentGroupId = message.group
            groupsStore.getSupportGroups().then(el => {
                if (groupsStore.currentGroupId) {
                    messagesStore.data.set(groupsStore.currentGroupId, messagesStore.data.get(currentGroupId) || observable([]))
                }
            })
        }

        if (res?.success && !res?.data?.results?.find((el: any) => el.status != 'ok')) {
            userMessage.status = StatusType.SENT
            if (res?.data?.results[0]?.CreateId) {
                userMessage.message.id = res.data.results[0].CreateId
                if (res?.data?.results[0]?.ImagesUrl?.length > 0) {
                    if (res?.data?.results[0]?.ImagesUrl?.length == 2) {
                        messagePayload = JSON.stringify({ 
                            text: this.messageText, 
                            files: [
                                {
                                  fileUrl: res?.data?.results[0]?.ImagesUrl[0], 
                                  type: this.videoThumbhail.type, 
                                },
                                {
                                    fileUrl: res?.data?.results[0]?.ImagesUrl[1], 
                                    type: this.files.type, 
                                }
                            ] 
                        })
                    } else {
                        messagePayload = JSON.stringify({ 
                            text: this.messageText, 
                            files: [{ fileUrl: res?.data?.results[0]?.ImagesUrl[0], type: this.files.type, }] 
                        })
                    }
                    
                    userMessage.message.payload = messagePayload
                }
            }
            messagesStore.updateMessage(userMessage)
            // messagesStore.refreshGroup()
        } else {
            userMessage.status = StatusType.FAILED
            messagesStore.updateMessage(userMessage)
            // messagesStore.refreshGroup()
        }

        this.clearAll()
    }

    @action updateMessageInFeed = async () => {
        const userMessage = this.currentUserMessage

        if (!userMessage) return
        const isSurvey = userMessage.message.msg_strategy.startsWith(StrategyType.survey)

        if (userMessage.message.msg_strategy === StrategyType.text) {
            userMessage.message.payload = this.messageText
        }

        else if (userMessage.message.msg_strategy === StrategyType.media) {
            if (this.files && this.files.local) {
                if (this.files.type === 'IMAGE') {
                    userMessage.message.payload = JSON.stringify({ text: this.messageText, files: [{ fileUrl: URL.createObjectURL(this.files.url), type: this.files.type, }] })    
                } else if (this.files.type === 'VIDEO') {
                    userMessage.message.payload = JSON.stringify({ 
                        text: this.messageText, 
                        files: [
                            { fileUrl: URL.createObjectURL(this.videoThumbhail?.url), type: this.videoThumbhail?.type, }, 
                            { fileUrl: URL.createObjectURL(this.files.url), type: this.files.type, }
                        ] 
                    })
                } else {
                    userMessage.message.payload = JSON.stringify({ text: this.messageText, files: [{ fileUrl: { url: URL.createObjectURL(this.files.url) }, type: this.files.type }] })
                }    
            }
        }

        else if (isSurvey) {
            userMessage.message.payload = JSON.stringify({ question: this.messageText, answers: this.surveyAnswers })
            userMessage.message.msg_strategy = StrategyType.survey + this.surveyAnswers!.length
        }

        userMessage.status = StatusType.OUTGOING
        userMessage.message.expiry = this.expiry
        userMessage.message.schedule = this.schedule
       
        if (this.schedule && isAfter(new Date(), new Date(this.schedule))) {
            userMessage.message.created = new Date()
        }

        messagesStore.refreshGroup()

        const message = userMessage.message
        let requestOp: MessageOp = {
            op: OperationType.UPDATE_MESSAGE,
            group: message.group,
            sender: message?.senderId,
            type: message.msg_strategy,
            payload: isSurvey ? userMessage.message.payload : this.messageText,
            expiry: message.expiry?.toISOString(),
            guid: message.create_guid,
            locationRequest: this.locationRequest,
            // guid: newGuid(),
            message: message.id,
            schedule: message.schedule?.toISOString(),
            id: 1
        }

        let blob = undefined
        if (this.files && this.files.local) {
            if (this.files?.type === 'IMAGE') {
                blob = this.files.url
                requestOp.files = [{ type: "IMAGE" }]
            }
            
            else if (this.files?.type === 'PDF') {
                blob = this.files.url
                requestOp.files = [{ type: "PDF" }]
            } 

            else if (this.files?.type === 'VIDEO') {
                blob = [this.videoThumbhail?.url, this.files.url]
                requestOp.files = [{ type: "IMAGE" }, { type: 'VIDEO' }]
            }

            const res = await api.updateMessageWithMedia(requestOp.message, [requestOp], blob) //this need

            if (res.statusCode === 'OK') {
                userMessage.status = StatusType.SENT
                messagesStore.updateMessage(userMessage)
            } else {
                userMessage.status = StatusType.FAILED
                messagesStore.updateMessage(userMessage)
            }
            
            this.clearAll()
        } else {
            const res = await api.updateMessage(requestOp) //this need

            if (res.statusCode === 'OK') {
                userMessage.status = StatusType.SENT
                messagesStore.updateMessage(userMessage)
            } else {
                userMessage.status = StatusType.FAILED
                messagesStore.updateMessage(userMessage)
            }

            this.clearAll()
        }
    }

    @action sendMessageAgain = async (userMessage: UserMessage) => {
        const message = userMessage.message
        userMessage.status = StatusType.OUTGOING
        messagesStore.refreshGroup()
        let blob: null | Blob | Blob[] = null
        let file: null | File = null
        let messageText: string = ''
        let filesType: null | string = null

        if (message.msg_strategy === 'MEDIA' && IsJsonString(message.payload)) {
            const parseString = JSON.parse(message.payload)

            messageText = parseString.text
            filesType = parseString.files[0].type
            file = await fetch(parseString.files[0].fileUrl).then(r => r.blob()).then(blobFile => new File([blobFile], filesType === "IMAGE" ? 'newImage.jpeg' : 'newDoc.pdf', { type: blobFile.type }))

            if (filesType === 'IMAGE') {
                blob = file
            } else if (filesType === 'PDF') {
                blob = file
            } else if (filesType == 'VIDEO') {
                blob = [this.videoThumbhail?.url, file]
            }
        } else {
            messageText = message.payload
        }

        let requestOp: MessageOp = {
            op: ((message.id && message.id < 0) || !message.id) ? (message.group < 0 ? OperationType.CREATE_SUPPORT_MESSAGE : OperationType.CREATE_MESSAGE) : OperationType.UPDATE_MESSAGE,
            group: message.group,
            sender: message?.senderId,
            type: message.msg_strategy,
            payload: messageText,
            expiry: message.expiry?.toISOString(),
            guid: message.create_guid,
            schedule: message.schedule?.toISOString(),
            locationRequest: message.locationRequest,
            id: 1,
        }

        if (message.id > 0) {
            requestOp.message = message.id
        }

        if (filesType) {
            requestOp.files = [{ type: filesType }]
        }

        const res = await api.sendMessage([requestOp], blob, groupsStore.currentGroup.group.type)

        if (res?.success && !res?.data?.results?.find((el: any) => el.status != 'ok')) {
            userMessage.status = StatusType.SENT

            if (res?.data?.results[0]?.CreateId) {
                userMessage.message.id = res.data.results[0].CreateId
            }

            messagesStore.refreshGroup()
        } else {
            userMessage.status = StatusType.FAILED
            messagesStore.refreshGroup()
        }
    }

    responseMessage = async (messageId: number, response: number, status: StatusType) => {
        const messageResponseOp = {
            messageId: messageId,
            status: status,
            response: response,
        }

        api.responseMessage(messageResponseOp)
    }

    groupBy = (items: any, key: string) => items.reduce(
        (result: any, item: any) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    )
    @observable messageResponsesByResponse: { [key: number]: MessageResponse[] } = []

    @action getMessageResponses = async (messageId: number) => {
        const res = await api.getMessageResponses(messageId);
        if (res?.status && res.status >= 400) {
            return {}
        }
        const responses = res?.filter((el: MessageResponse) => el.user_ref != 1 && el.user_ref != 581)
        const surveyResponses = this.groupBy(responses, 'response')
        delete surveyResponses[0]
        this.messageResponsesByResponse = surveyResponses
        return this.groupBy(responses, 'status')
    }

    @action clearAll = () => {
        this.files = undefined
        this.surveyAnswers = undefined
        this.currentUserMessage = undefined
        this.messageText = ''
        this.locationRequest = false
    };



}

const newMessageStore = new NewMessageStore()
export default newMessageStore
