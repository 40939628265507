import { action, computed, observable, reaction, toJS, IObservableArray, ObservableMap } from 'mobx'

import groups from 'store/groups.store'
import userStore from 'store/user.store'
import api from 'service/api'
import { LastMessage, Message, UserMessage } from 'utils/models'
import { ID, StrategyType, SurveyAnswer, StatusType, MessageDialogMode, MessageResponseOp, PayloadRequest, GroupType } from 'utils/types'
import { LOADERS } from 'utils/loaders'
import { isAfter, parseISO, startOfDay } from 'date-fns'
import user from 'store/user.store'
import orgsStore from './orgs.store'
import groupsStore from 'store/groups.store'
import veryLocalStorage, { storageKeys } from 'utils/vls'
import { OperationType } from '../utils/operation'
import { newGuid } from '../utils/generates';
import { AlarmMessage } from '../utils/types'


class MessagesStore {

  constructor() {
    reaction(
      () => groups.currentGroupId,
      (id, prev) => {
        id && this.getMessages(id)
      },
      { fireImmediately: true }
    )
    reaction(
      () => orgsStore.currentOrgId,
      (id?: number) => {
        if (id) {
          api.getCommunityMessages(id).then((res: UserMessage[]) => {
            res.sort((a, b) => {
              return new Date(a.message.created).getTime() - new Date(b.message.created).getTime()
            })
            const groupMessagesMap: Map<number, UserMessage[]> = new Map()
            this.markMessagesRead(res.filter(el => el.status < StatusType.RECEIVED), StatusType.RECEIVED)
            res.forEach(el => {
              const messages = groupMessagesMap.get(el.message.group)
              if (!messages) {
                groupMessagesMap.set(el.message.group, ([el]))
              } else {
                messages.push(el)
              }
            })
            this.setData(groupMessagesMap)
            groupMessagesMap.forEach(groupMessages => {
              const filteredMessages = groupMessages.filter(el => !el.message.schedule)
              if (filteredMessages.length === 0) {
                return;
              }
              const max = filteredMessages.reduce(function (prev, current) {
                return isAfter(new Date(prev.message.created), new Date(current.message.created)) ? prev : current
              }) //returns object
              const userId = userStore.user?.user_id
              const unreadMessages = groupMessages.filter(el => el.message.senderId != userId && el.status < StatusType.READ)
              groupsStore.setLastMessage(max.message.group, { lastMessage: max, unread: unreadMessages.length })
            })
            this.setLastMessagesLoading(false)
          })
        }
      }, { fireImmediately: true }
    )
  }

  @observable loadingQueue: Set<string> = new Set<string>()
  @observable lastMessagesLoading = true
  @observable userDetailsModalState: boolean = false
  @observable userDetailsId: number | null = null


  @action openUserDetailsModal(id: number) {
    if (id) {
      this.userDetailsId = id
    }
    this.userDetailsModalState = true
  }

  @action closeUserDetailsModal() {
    this.userDetailsId = null
    this.userDetailsModalState = false
  }

  @action setLastMessagesLoading = (bool: boolean) => {
    this.lastMessagesLoading = bool;
  }
  @computed
  get isLoading() {
    return this.loadingQueue.size > 0
  }

  @action
  setLoading = (loading: boolean, id: string | LOADERS) => {
    loading ? this.loadingQueue.add(id) : this.loadingQueue.delete(id)
  }



  @observable data: ObservableMap<number, IObservableArray<UserMessage>> = new ObservableMap()
  
  @action mergeData(rawMessages: any) {
    const groupId = groupsStore.currentGroup?.group.id || veryLocalStorage.get(storageKeys.currentGroupId) || -1
    const currentMessages = toJS(this.data.get(groupId) || [])
    const newMessages = rawMessages.map((rawMessage: any) => {
      let msg: UserMessage = {
        user: userStore.user?.user_id,
        status: rawMessage.status,
        response: rawMessage.response ? rawMessage.response : 0,
        message: {
          author_name: rawMessage.author_name,
          create_guid: rawMessage.create_guid,
          created: rawMessage.created ? new Date(rawMessage.created) : rawMessage.created,
          expiry: rawMessage.expiry ? new Date(rawMessage.expiry) : rawMessage.expiry,
          group: rawMessage.group_ref,
          id: rawMessage.id,
          msg_strategy: rawMessage.msg_strategy,
          payload: rawMessage.payload,
          received: rawMessage.received,
          schedule: rawMessage.schedule ? new Date(rawMessage.schedule) : rawMessage.schedule,
          senderId: rawMessage.author_ref,
          senderName: rawMessage.author_name,
          updated: rawMessage.updated ? new Date(rawMessage.updated) : rawMessage.updated,
          locationRequest: rawMessage.locationRequest ? rawMessage.locationRequest : false 
        }
      }
      return msg
    })

    let messageids = {}
    const mergedMessages = currentMessages.concat(newMessages).filter(item => {
      if (item.message.id in messageids) {
        return false
      }

      messageids[item.message.id] = 0
      return true
    });

    mergedMessages.sort((a, b) => {
      return a.message.created.getTime() - b.message.created.getTime()
    })

    this.data.set(groupId, observable(mergedMessages))
  }

  @action setData(newData: Map<number, UserMessage[]>) {
    this.data.replace(observable(newData))
  }
  @computed get groupMessages() {
    return this.data.get(groupsStore.currentGroup?.group.id || 0) || []
  }
  @action addMessage = (userMessage: UserMessage) => {
    if (!groupsStore.currentGroup) {
      return
    }
    // userMessage.message.created = userMessage.message.created.toISOString()
    let groupMessages = this.data.get(groupsStore.currentGroup?.group.id)
    if (!groupMessages) {
      this.data.set(groupsStore.currentGroup?.group.id || -1, observable([]))
      groupMessages = this.data.get(groupsStore.currentGroup?.group.id)
    }
    groupMessages?.push(userMessage)
    if (!userMessage.message.schedule) {
      groupsStore.lastMessages.set(userMessage.message.group, { unread: 0, lastMessage: userMessage })
    }
    setTimeout(() => {
      this.ScrollFeedToEnd(true)
    }, 0);

  }
  @action updateMessage = (updateUserMessage: UserMessage) => {
    if (!groupsStore.currentGroup) {
      return
    }
    let groupMessages = this.data.get(groupsStore.currentGroup?.group.id)
    if (groupMessages) {
      const index = groupMessages.findIndex(u => u.message.id === updateUserMessage.message.id)
      groupMessages[index] = updateUserMessage
    } else {
      this.data.set(groupsStore.currentGroup?.group.id || -1, observable([updateUserMessage]))
    }
    const lastMessage = groupsStore.lastMessages.get(updateUserMessage.message.group)
    if (lastMessage && lastMessage.lastMessage.message.id === updateUserMessage.message.id) {
      groupsStore.lastMessages.set(updateUserMessage.message.group, { ...lastMessage, lastMessage: updateUserMessage })
    }
  }

  @observable currentMessageId: string | number | undefined = undefined

  @computed get currentMessage() {
    if (this.currentMessageId) {
      return this.getById(Number(this.currentMessageId)) || { id: undefined, text: '', author: userStore.user.given_name, attachment: '', author_ref: 0 }
    }
    return { id: undefined, text: '', author: userStore.user.given_name, attachment: '', author_ref: 0 }
  }
  @action getMessages = async (id: number) => {
    // if id === 1 its means Entity group, and for entity we don't need getting messages
    if (id < 0 || id === 1) {
      this.data.set(-1, observable([]))
      // this.setLoading(false, LOADERS.GET_MESSAGES)
      return
    }
    // this.setLoading(true, LOADERS.GET_MESSAGES)
    // this.data.replace([])
    const userId = userStore.user?.user_id
    const res = await api.getGroupMessages(id)
    res.sort((a, b) => {
      return a.message.created.getTime() - b.message.created.getTime()
    })
    // const res2 = await api.getCommunityMessages(orgsStore.currentOrg.id)
    this.data.set(groupsStore.currentGroup?.group.id || veryLocalStorage.get(storageKeys.currentGroupId) || -1, observable(res))
    this.setLoading(false, LOADERS.GET_MESSAGES)
  }
  // @action getMessages = async (id: string) => {
  //   console.log('id' ,id)
  //   if (id === -1) {
  //     this.data.set(-1, observable([]))
  //     this.setLoading(false, LOADERS.GET_MESSAGES)
  //     return
  //   }

  //   this.setLoading(true, LOADERS.GET_MESSAGES)
  //   // this.data.replace([])
  //   const userId = userStore.user?.user_id
  //   let res = await api.getGroupMessages(id)
  //   if (res.length === 0) {
  //     res = await (await api.getCommunityMessages(orgsStore.currentOrg.id)).filter(m => m.message.group === id)
  //   }
  //   this.data.set(groupsStore.currentGroup?.group.id, observable(res))
  //   this.setLoading(false, LOADERS.GET_MESSAGES)
  // }
  @action RefreshMessages = async (id: number) => {
    const newMessages = await api.getGroupMessages(id)

    // this.data = [...newMessages]
    // this.data.replace(newMessages)
    // this.data.set(groupsStore.currentGroup?.group.id, observable(newMessages))
  }
  @action refreshGroup = async () => {
    // this.data = [...this.data]
    // this.data.set(groupsStore.currentGroup?.group.id, this.data.get(groupsStore.currentGroup?.group.id) || observable([]))
    this.data.replace(this.data)
  }

  @observable scrollFeed = false

  @action ScrollFeedToEnd = (type: boolean) => {
    this.scrollFeed = type
  }


  @computed
  get messageFeedSections() {
    // accumulate sections and dates
    let sections: Map<number, UserMessage[]> = new Map()
    this.groupMessages?.forEach((message: UserMessage) => {
      // @ts-ignore
      let sectionDate = startOfDay(parseISO(message.message.created)).getTime()
      if (isNaN(sectionDate)) {
        sectionDate = startOfDay(parseISO(message.message.created.toISOString())).getTime()
      }
      const section = sections.get(sectionDate)
      section ? section.push(message) : sections.set(sectionDate, [message])
    })
    // sort dates
    const sortedKeys = Array.from(sections.keys()).sort((a: number, b: number) => a - b)
    // add relevant dates to each sorted date
    const sortedSections: Map<number, UserMessage[]> = new Map()
    sortedKeys.forEach((key: number) => {
      const section = sections.get(key)
      // @ts-ignore
      const sortedMessages: UserMessage[] = section ? section.sort((a, b) => new Date(a.message.created).getTime() - new Date(b.message.created).getTime()) : []
      sortedSections.set(key, sortedMessages)
    })
    const result: { date: number, messages: UserMessage[] }[] = []
    Array.from(sortedSections.entries()).forEach(el => {
      result.push({ date: el[0], messages: el[1] })
    })
    return toJS(result)
  }

  getById = (id: ID) => {
    return this.groupMessages.find(msg => msg.message.id === id)
  }

  @observable isModalOpen = false
  @action setIsModalOpen = (state: boolean) => this.isModalOpen = state

  @observable messageDetailsModalOpen = false
  @action setMessageDetailsModalOpen = (state: boolean) => this.messageDetailsModalOpen = state

  @observable messageScheduleModalOpen = false
  @action setMessageScheduleModalOpen = (state: boolean) => this.messageScheduleModalOpen = state

  @observable viewerModalOpen = false
  @action setViewerModalOpen = (state: boolean) => this.viewerModalOpen = state
  @observable viewerModalFile: { type: string | null, file: any } = { type: null, file: null }
  @action setViewerModalFile = (state: { type: string, file: any }) => this.viewerModalFile = state

  openNewMessageDialog = () => {
    this.setIsModalOpen(true)
  }
  closeNewMessageDialog = () => {
    this.setIsModalOpen(false)
  }
  edit = (id: string | number) => {
    this.currentMessageId = id
    this.setIsModalOpen(true)
  }

  openMessageDetails = async (id: string | number | undefined) => {
    if (id === undefined) {
      return
    }
    await groups.initGroupMembers(groups.currentGroupId)
    this.currentMessageId = id
    this.setMessageDetailsModalOpen(true)
  }

  openViewerModal = (type: string, file: any) => {
    if (!type && !file) {
      return
    }
    this.setViewerModalFile({ type, file })
    this.setViewerModalOpen(true)
  }

  closeViewerModal = () => {
    this.setViewerModalOpen(false)
  }

  createMessagePayload = async (message: Message, file: File | undefined, surveyAnswers: Array<SurveyAnswer> | undefined): Promise<PayloadRequest> => {
    const messagePayload: any = { files: null, payload: null, type: null, blob: null }
    if (surveyAnswers) {
      messagePayload.payload = JSON.stringify({
        question: message.payload,
        answers: surveyAnswers
      })
      message.msg_strategy = StrategyType.survey
      message.payload = messagePayload.payload
    }
    else if (file && message.msg_strategy == StrategyType.media) {
      messagePayload.blob = file
      messagePayload.files = [{ type: "IMAGE" }]
    } else if (file && message.msg_strategy == StrategyType.pdf) {
      messagePayload.blob = file
      messagePayload.files = [{ type: "PDF" }]
      messagePayload.type = StrategyType.media //Server use Also Media so send it as type .media
    }
    messagePayload.payload = message.payload
    return messagePayload
  }


  @action remove = async (id: number | undefined) => {
    if (id && groupsStore.currentGroup) {
      let res = await api.deleteMessage(id)
      if ((!res?.status || res.status < 400) && !res?.errors?.length) {
        this.data.get(groupsStore.currentGroup?.group.id)?.replace(this.groupMessages.filter(message => message.message.id !== id))
      }
      // TODO: error handling if removal failed
    }
    return 0
  }

  @action updateMessages = (userMessage: UserMessage[] = []) => {
    userMessage.forEach(el => this.updateMessage(el))
  };

  @action markMessagesRead = async (messages: UserMessage[], statusType: StatusType = StatusType.READ) => {
    try {
      if (messages.length) {
        let messageIdsWithLocation = []

        messages.forEach(el => {
          if (el.message.locationRequest && 
            el.status == StatusType.RECEIVED && 
            statusType == StatusType.READ &&
            groupsStore.currentGroup?.group.type !== GroupType.SUPPORT
            ) {
              messageIdsWithLocation.push(el.message.id);
          }

          el.status = statusType
        })

        this.updateMessages(messages)
        const userId = userStore.user?.user_id || 0
        const requestOps = messages.map((msg, index) => {
          const responsesRequest: MessageResponseOp = {
            messageId: msg.message.id,
            status: msg.status,
            response: msg.response,
          }
          return responsesRequest
        })

        await api.responseMessage(requestOps)

        if (groupsStore.currentGroupId) {
          //await api.responseGroupMessages(groupsStore.currentGroupId, StatusType.READ as number)
          const lastMessage = groupsStore.lastMessages.get(groupsStore.currentGroupId)
          
          if (lastMessage) {
            lastMessage.unread = 0
          }
        }

        if (messageIdsWithLocation.length > 0) {
          console.log("send location")
          const sendDeviceLocation = (attempt = 1) => {
            const MAX_ACCURACY = 50

            navigator.geolocation.getCurrentPosition((position) => {
              console.log("Accurancy", position.coords.accuracy);
            
              if (position.coords.accuracy > MAX_ACCURACY && attempt < 5) {
                setTimeout(() => {
                  sendDeviceLocation(++attempt);
                }, 3000);
                return; 
              }
      
              messageIdsWithLocation.map(messageId => {
                api.sendDeviceLocation({
                  messageId,
                  deviceId: "",
                  deviceDetails: navigator.userAgent,
                  note: "בקשת מקום בקריאת הודעה",
                  accuracy: position.coords.accuracy,
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                  address: "",
                  communityId: orgsStore.currentOrgId == undefined ? null : orgsStore.currentOrgId,
                })    
              })
            
            }, (error) => {
              if (error.code == error.PERMISSION_DENIED) {
                console.log("Location not allowed");
              }
            });  
          }
        
          sendDeviceLocation()
        }
      }    
    } catch (e) {
      console.log('markMessagesRead error:', e)
    }

  };

  @action async lazyloadGroupMessages(data: { pageNumber: number, pageSize: number, groupId: number }) {
    const response = await api.lazyloadGroupMessages(data);

    this.mergeData(response)
    return { messages: response }
  }

  // @action close = () => {
  //   this.setIsModalOpen(false)
  //   this.currentMessageId = undefined
  // }

}

const messages = new MessagesStore()
export default messages
