import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Dialog } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { theme, Text, Space, Button, Spinner } from 'ui'
import { observer } from 'mobx-react'
import useStore from 'store'
import { memberErrorsType } from 'store/member.store'
import MessagesHeader from 'components/Messages/MessagesHeader'
import CreateUserForm from './CreateUserForm'
import UserGroupsList from './UserGroupsList'
import { parsePhoneNumberFromString, isValidNumber } from 'libphonenumber-js'
import validator from 'validator'
import DuplicatedDialog from 'components/Members/DuplicatedDialog'
import { userCanSeeAssociatedGroups } from 'utils/roles'
import { errorType } from 'utils/models'


const ContainerDialog = styled.div<{ blur: boolean }>`
    flex-direction:column;
    overflow:auto;
    display:flex;

    justify-content:space-between;
    filter:${p => p.blur ? 'blur(2px)' : 0};
  

`
const Container = styled.div`
    flex-direction:column;
    overflow:auto;
    display:flex;
    /* margin:0 10px 10px 10px; */
    height:80vh;
    max-height:750px;
    width:700px;
        @media (max-width: 768px) {
      width: unset;
    }
    position:relative;

`
const Overlay = styled.div`
   position:absolute;
   display:flex;
   justify-content:center;
   align-items:center;
   width:100%;
   height:100%;
   z-index:10;
`
const RowContainer = styled.div`
    flex-direction:row;
    display:flex;
    flex:1;
    margin-top:10px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
`
const MobileGroupTitle = styled.div`
  font-size: 1.4rem;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const ConfirmButton = styled(Button) <{ bgColor: string, hoverColor?: string }>`
  &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background:${p => p.bgColor};
    &:hover {
      background: ${p => p.hoverColor ? p.hoverColor : theme.color.darkBlue};
    }
    color: ${theme.color.white};
    &.Mui-disabled {
      opacity: 0.5;
    }
  }

`
const ConfirmButtonsContainer = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    padding:10px;
`

const CreateEditModal: FC = ({ }) => {
  const { members, orgs, uiStore, memberStore } = useStore()
  const { t } = useTranslation('members')
  const currentMember = memberStore.getCurrentMemebr
  const showInAlphonRef = React.useRef(currentMember)
  React.useEffect(() => {
    showInAlphonRef.current = currentMember
  }, [memberStore.createEditUserModalState])
  if (!currentMember) {
    return null
  }
  const updateUser = currentMember?.user_id != -1
  const displayName = `${currentMember?.family_name} ${currentMember?.nick_name ? ' (' + currentMember.nick_name + ') ' : ''} ${currentMember?.given_name}`

  const handleRemove = () => {
    if (currentMember?.user_id > 0) {
      uiStore.openWarningModal({
        text: t`remove:removeUser`,
        secondText: displayName,
        okAction: () => {
          members.removeFromCommunity(currentMember?.user_id)
          memberStore.closeCreateEditModal()
        }
      })
    }
  }
  const validate = () => {
    const emailRegex = /^[-a-zA-Z0-9!"#$&'()*+.,_^~]+@[-a-zA-Z0-9]+(?:\.[-a-zA-Z0-9]+)+$/

    let valid = true
    if (currentMember.phones[0] && !isValidNumber(currentMember.phones[0], 'IL')) {
      memberStore.setError(memberErrorsType.phoneNumber1, errorType.InvalidPhoneNumber, t`invalidPhone`)
      valid = false
    }
    if (currentMember.phones[1] && !isValidNumber(currentMember.phones[1], 'IL')) {
      memberStore.setError(memberErrorsType.phoneNumber2, errorType.InvalidPhoneNumber, t`invalidPhone`)
      valid = false
    }
    if (currentMember.emails[0] && !emailRegex.test(currentMember.emails[0])) {
      memberStore.setError(memberErrorsType.email, errorType.InvalidEmailAddress, t`invalidEmail`)
      valid = false
    }
    if (currentMember.emails[0] && currentMember.emails[0].trim() == "mekomeapp@gmail.com") {
      memberStore.setError(memberErrorsType.email, errorType.InvalidEmailAddress, t`disabledEmail`)
      valid = false
    }
    return valid
  }

  const handleOk = () => {
    if (validate()) {
      memberStore.createUpdateUser(showInAlphonRef.current?.is_showInAlphon != currentMember.is_showInAlphon)
    }
  }

  const isSendDisabled = () => {
    if (currentMember?.given_name === '' && currentMember?.family_name === '' && currentMember?.nick_name === '') {
      return true
    }
    if (memberStore.errors.get(memberErrorsType.phoneNumber1) ||
      memberStore.errors.get(memberErrorsType.phoneNumber2) ||
      memberStore.errors.get(memberErrorsType.email) ||
      memberStore.errors.get(memberErrorsType.NickName) ||
      memberStore.errors.get(memberErrorsType.GivenName) ||
      memberStore.errors.get(memberErrorsType.FamilyName)) {
      return true
    }
    if (updateUser && (memberStore.addedUserGroups.length === 0 && memberStore.removeUserGroups.length === 0 && !memberStore.formChange)) {
      return true
    }
    // if (updateUser  && (currentMember.family_name?.localeCompare(memberStore.lastName) === 0) &&
    //   (memberStore.currentMember.given_name?.localeCompare(memberStore.firstName) === 0) &&
    //   (memberStore.currentMember.nick_name?.localeCompare(memberStore.nickName) === 0) &&
    //   ((memberStore?.currentMember?.phones?.length > 0) ? parsePhoneNumberFromString(memberStore?.currentMember?.phones[0])?.formatNational().localeCompare(memberStore.phone1) >= 0 : memberStore.phone1 === '') &&
    //   ((memberStore?.currentMember?.phones?.length > 1) ? parsePhoneNumberFromString(memberStore?.currentMember?.phones[1])?.formatNational().localeCompare(memberStore.phone2) >= 0 : memberStore.phone2 === '') &&
    //   ((memberStore?.currentMember?.emails?.length > 0) ? memberStore?.currentMember?.emails[0]?.localeCompare(memberStore.email) >= 0 : memberStore.email === '') &&
    //   ((memberStore?.currentMember?.is_showInAlphon) == memberStore.showUserInAlphone)
    // ) {

    return false
  }


  return (
    <>
      <Dialog
        style={{ zIndex: 1200 }}
        open={memberStore.createEditUserModalState}
        maxWidth={'xl'}
      >
        <MessagesHeader
          disableIcon
          textVarient={'black'}
          backColor={theme.color.azureBlue + 33}
          mainText={orgs.currentOrg.name}
          secondText={(updateUser ? t`edit` : t`add`) + '\xa0' + displayName}
          onClose={memberStore.loadingRequest ? () => { } : memberStore.closeCreateEditModal}
        />
        <Container>
          {memberStore.loadingRequest && <Overlay>
            <Spinner />
          </Overlay>}
          <ContainerDialog blur={memberStore.loadingRequest}>

            {updateUser ? <></> : <Text
              align={'center'}
              style={{ paddingTop: 10 }}
              textcolor={theme.color.black + 99}
              weight={'medium'}
              size={'xl'}
            >{t`fill`}</Text>}
            <RowContainer>
              <CreateUserForm />
              <Space />
              <MobileGroupTitle>להזמין לקבוצות</MobileGroupTitle>
              <UserGroupsList />
            </RowContainer>
          </ContainerDialog>
        </Container>
        <ConfirmButtonsContainer>
          <div>
            {updateUser && memberStore.duplicatedUser === null ? <ConfirmButton
              disabled={memberStore.loadingRequest ? true : false}
              hoverColor={theme.color.dangerRed}
              bgColor={theme.color.red}
              onClick={handleRemove}>{t`deleteUser`}
            </ConfirmButton> : <div />}
            {userCanSeeAssociatedGroups() && updateUser && <ConfirmButton
              bgColor={theme.color.blue}
              onClick={() => memberStore.deleteUserPushToken()}>{t`app:deleteUserPush`}
            </ConfirmButton>}
          </div>
          <ConfirmButton
            disabled={memberStore.loadingRequest ? true : isSendDisabled()}
            bgColor={theme.color.confirmButton}
            onClick={handleOk}>{updateUser ? t`app:save` : t`app:add`}
          </ConfirmButton>
        </ConfirmButtonsContainer>
      </Dialog>
      <DuplicatedDialog />
    </>

  )
}

export default observer(CreateEditModal)


